/* eslint-disable react/no-is-mounted */
import React, { createRef } from 'react';
import { connect } from 'react-redux';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import Router from 'next/router';
import classNames from 'classnames';
/* eslint-disable-next-line no-restricted-imports */
import styled, { keyframes } from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import Logo from 'Containers/common/logo';
import HeaderLoginBox from 'Containers/desktop/headerLoginBox';

import Conditional from 'Components/common/conditional';
import Loader from 'Components/common/loader';
import DownloadAppSection from 'Components/desktop/downloadAppSection';
import HoverLink from 'Components/desktop/hoverLink';
import LanguageCurrencyPicker from 'Components/desktop/languageCurrencySelector/languageCurrencyPicker';

import { AppIconSvg } from 'Assets/svg/header/AppIconSvg';
import { HamburgerIcon } from 'Assets/svg/header/HamburgerIcon';
import { HelpIcon } from 'Assets/svg/header/HelpIcon';

import { trackEvent } from 'Utils/analytics';
import { showCategories } from 'Utils/categoryUtils';
import {
	getCollectionLimit,
	getCollectionUrlAsPath,
	getCollectionUrlHref,
	getTopCollectionCardInfoByCityCode,
} from 'Utils/collectionsUtils';
import { isServer } from 'Utils/envUtils';
import {
	checkIfClassNameExistsInList,
	checkIfErrorPage,
	getHeightForContainerInPixels,
	getNodeFromClassName,
} from 'Utils/gen';
import { isWhitelabel } from 'Utils/hostUtils';
import { error } from 'Utils/logUtils';
import {
	getCategoriesInfoByCityCode,
	getCitiesMap,
	getCurrenciesMap,
	getCurrentCity,
	getCurrentCityCode,
	getCurrentCurrency,
	getCurrentLanguageCode,
	getCurrentPage,
	getDomainConfig,
	getFetchingStatus,
	getHost,
	getLanguages,
	getLocalizedContentLanguageCode,
	getUser,
	getUserLandingUrl,
	isBot,
} from 'Utils/stateUtils';
import {
	doesCurrentRouteIncludeString,
	getWebPathString,
	isExperiencePageUrl,
	isHomePageUrl,
} from 'Utils/urlUtils';

import { fetchCategoriesByCityCode } from 'Thunks/categories';
import { fetchCollections } from 'Thunks/collections';
import { changeCity } from 'Actions/city';

import {
	ALL_COLLECTIONS,
	DESKTOP_HEADER_CLASSNAME,
	DESKTOP_HEADER_Z_INDEX,
	GLOBAL_CITY_CODE,
	HEADOUT_SUPPORT_LINE,
	PAGE_TYPE,
	SEARCH_LOCATION,
	SORT_TYPE,
} from 'Constants/constants';
import { strings } from 'Constants/strings';

import colors from 'Static/typography/colors';
import TYPE_LABELS from 'Static/typography/labels';

const UniversalSearch = dynamic(
	() => import('Containers/desktop/universalSearch'),
);
const CategoriesDropdownInformation = dynamic(
	() => import('Containers/desktop/categoriesDropdownInformation'),
	{
		ssr: false,
	},
);

const OverflowAnimation = keyframes`
  0% {
    overflow: hidden;
  }
  100% {
    overflow: visible;
  }
`;

const transparentBgGradient = `
	background: linear-gradient(180deg,rgb(0 0 0 / 60%) -28.88%,rgba(0,0,0,0) 100%);
`;

const HeaderWrapperStyled = styled.header<{
	$transparentBg?: boolean;
	$darkPurpleBg?: boolean;
	$currentPage?: string;
	$isAtTop?: boolean;
}>`
	position: sticky;
	padding-bottom: 0.1px;
	top: 0;
	width: 100%;
	background-color: ${({ $transparentBg, $darkPurpleBg }) =>
		!$transparentBg && !$darkPurpleBg && colors.WHITE};
	${({ $darkPurpleBg }) =>
		$darkPurpleBg && `background: ${colors.DARK_PURPLE}`};
	${({ $transparentBg }) => $transparentBg && transparentBgGradient};
	z-index: ${DESKTOP_HEADER_Z_INDEX};
	transform: translateZ(0);
	outline-color: ${({ $isAtTop, $transparentBg }) =>
		$isAtTop || $transparentBg ? 'transparent' : colors.GREY_DS.G6};
	outline-width: ${({ $isAtTop, $transparentBg }) =>
		$isAtTop || $transparentBg ? 0 : '1px'};
	outline-style: solid;
	transition: outline-width 0.1s cubic-bezier(0.7, 0, 0.3, 1);

	.header {
		min-width: 62.5rem;
		max-width: 75rem;
		margin: 0 auto;
		justify-content: space-between;
		padding-top: 1rem;

		.header-first-row {
			display: flex;
			height: 3rem;
			flex: 1;
			align-items: center;
			justify-content: space-between;
			margin-bottom: 1rem;

			.logo-wrapper {
				margin-right: 1rem;
				display: flex;
				justify-content: center;
				flex-direction: column;

				.main-logo-wrapper {
					height: 24px;

					img {
						&.whitewash {
							filter: brightness(0) invert(1);
						}
					}
				}
			}

			.header-element-section-wrapper {
				display: flex;
				align-items: center;
			}

			.sign-out-link {
				line-height: 2.5rem;
				margin-left: 2.5rem;

				&:hover {
					color: ${colors.PURPS};
				}
			}

			.core-loading-dots {
				margin: 0 0.75rem;
				width: auto;
			}
		}

		.left,
		.right {
			display: flex;
		}

		.left {
			max-width: calc(100% - 10rem);
			position: relative;

			&:after {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				height: 100%;
				width: 0;
				opacity: 0;
				background: linear-gradient(
					270deg,
					#ffffff 23.07%,
					rgba(255, 255, 255, 0) 100%
				);
				z-index: 2;
				transition: opacity 300ms ease-in;
			}

			&.scrollable {
				&:after {
					opacity: 1;
					width: 11.25rem;
				}

				.left-scroll-container > .header-element-wrapper:last-of-type {
					margin-right: 11.25rem;
				}
			}
		}

		.left-scroll-container {
			display: flex;
			width: 100%;
			overflow: auto;
			white-space: nowrap;
			position: relative;

			&::-webkit-scrollbar {
				display: none;
			}
		}

		.headout-picks-popup-div {
			position: relative;

			&:after {
				position: absolute;
				top: 0;
				right: -7.5rem;
				content: '';
				width: 0;
				height: 100%;
				background: linear-gradient(
					90deg,
					#ffffff 23.07%,
					rgba(255, 255, 255, 0) 100%
				);
				opacity: 0;
				z-index: 2;
				transition: opacity 300ms ease-in;
			}

			&.scrolled {
				&:after {
					width: 7.5rem;
					opacity: 1;
				}
			}
		}

		.highlightText {
			svg line {
				stroke: ${colors.PURPS} !important;
			}
		}

		.header-second-row {
			display: flex;
			justify-content: space-between;
			align-items: start;
			height: 3rem;
			transition: all 0.3s linear 0s;
			animation: ${OverflowAnimation} 0.3s 1;
			margin-bottom: 0.0625rem;

			.categories-dropdown-header {
				.categories-dropdown-small-text {
					font-size: 0.75rem;
				}

				.categories-dropdown-large-text {
					display: flex;
					align-items: center;
					font-size: 10px;

					svg {
						width: 1rem;
						height: 1rem;
						margin-right: 0.5rem;
						transform: translateY(1.5px);
					}
				}

				&:hover {
					.categories-dropdown-large-text {
						svg path {
							stroke: ${colors.PURPS};
						}

						svg line {
							stroke: ${colors.PURPS} !important;
						}

						color: ${colors.PURPS};
					}
				}
			}

			.popup-container {
				position: absolute;
				top: 0;
				display: flex;
				flex-direction: column;
				width: 100%;

				.popup-div {
					padding: 0;
					transform: scaleY(0);
					transform-origin: top;
					overflow: hidden;
					transition: all 0.2s ease-in-out 0s !important;
					opacity: 0;
					visibility: hidden;

					&.popup-center-align {
						align-self: center;
						left: auto;
					}

					&.popup-left-align {
						align-self: flex-start;
						left: auto;
					}

					&.popup-right-align {
						align-self: flex-end;
						right: auto;
					}
				}

				&.show-popup {
					.popup-div {
						overflow: visible;
						opacity: 1;
						transform: scaleY(1);
						visibility: visible;
					}

					.currency-popup-div {
						overflow: auto;
					}
				}
			}
		}

		.header-element-wrapper {
			position: relative;
			font-size: 10px;
			margin-right: 1.875rem;
			cursor: pointer !important;
			padding: 0.75rem 0;

			&:hover {
				span.header-element-text {
					color: ${colors.PURPS} !important;
					display: inline-block;
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}

			.header-element {
				cursor: pointer;
				white-space: nowrap;
				display: flex;
				align-items: center;
				justify-content: center;
				${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_MEDIUM)};

				&:hover {
					color: ${colors.PURPS};
				}

				&:hover {
					color: ${colors.PURPS};
				}
			}
		}
	}
`;

const HeaderElementSection = styled.div`
	display: flex;
	margin-left: 0.5rem;
	cursor: pointer;
	padding: 0.5625rem 0.75rem 0.6875rem;
	position: relative;
	align-items: center;
	justify-content: center;
	user-select: none;

	.descriptionIcon {
		width: 1rem;
		height: 1rem;
	}

	.elementText {
		color: ${colors.GREY['66']};
		color: ${({
			// @ts-expect-error TS(2339): Property '$whiteText' does not exist on type 'Pick... Remove this comment to see the full error message
			$whiteText,
		}) => ($whiteText ? colors.WHITE : colors.GREY['44'])};
		${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_REGULAR)};
		margin-left: 0.25rem;
		background-color: ${colors.TRANSPARENT};
	}

	.no-margin {
		margin-left: 0;
	}

	&:hover,
	&.selected {
		background: ${({
			// @ts-expect-error TS(2339): Property '$whiteText' does not exist on type 'Pick... Remove this comment to see the full error message
			$whiteText,
		}) => ($whiteText ? '#00000034' : colors.GREY['F8'])};
		border-radius: 0.75rem;
	}

	&.selected {
		pointer-events: none;
	}

	.helpIcon {
		width: 1rem;
		height: 1rem;
	}
`;

const StyledSpan = styled.span`
	&.header-element-text {
		color: ${colors.GREY['66']};
		${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_MEDIUM)};
	}
`;

const GhostViewSection = styled.div`
	margin: 0;
	flex: 1;
	display: flex;
	height: 3rem;
`;

export const DropdownContainer = styled.div`
	display: flex;
	position: absolute;
	top: 3rem;
	background: ${colors.WHITE};
	border-radius: 0.25rem;
	padding: 0.75rem 0;
	left: 0;
	width: max-content;
	z-index: 99999;
	max-height: 37.5rem;
	overflow-y: auto;
	pointer-events: auto;

	&.currency-language-selector {
		padding: initial;
		box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1),
			0px 2px 8px rgba(0, 0, 0, 0.1);
		border-radius: 8px;
		top: 3.75rem;
		transform: translateX(min(-68%, -400px));
		max-height: initial;
	}

	&.fixed-width {
		width: 9.25rem;
	}

	&.full-width {
		width: 100vw;
	}

	&.app-download-section {
		display: flex;
		flex: 1;
		top: 7.5rem;
		height: 0;
		border: 0;
		padding: 0;
		transition: height 200ms ease-in-out !important;
		box-shadow: none;
		visibility: hidden;
	}

	&.show-app-section {
		height: 24.125rem;
		border-bottom: 1px solid ${colors.GREY['E2']};
		visibility: visible;
		overflow: hidden;
	}

	&.scrollable {
		&::-webkit-scrollbar-track {
			background-color: transparent;
		}

		&::-webkit-scrollbar {
			width: 0.125rem;
			height: 0.125rem;
		}

		&::-webkit-scrollbar-thumb {
			background-color: ${colors.GREY['75']};
		}
	}

	&.hidden {
		display: none;
	}
`;

const AppDownloadSection = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0.75rem 0;

	&:hover {
		.elementText {
			color: ${colors.PURPS};
		}

		svg path {
			stroke: ${colors.PURPS};
		}
	}

	.app-icon {
		width: 1rem;
		height: 1rem;
		cursor: pointer;
	}

	.elementText {
		color: ${colors.GREY['66']};
		${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_MEDIUM)};
		margin-left: 0.25rem;
		cursor: pointer;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;

type HeaderElementsProps = {
	paramLang?: string;
	currentPage?: string;
	currentCityCode?: string;
	currencyCode?: string;
	languageCode?: string;
	landingUrl?: string;
	currentCityPhoneNumber?: string;
	onMountDispatch?: (...args: any[]) => any;
	onCitySelected?: (...args: any[]) => any;
	dispatchFromNonCities?: (...args: any[]) => any;
	booking?: any;
	fetchingStatus?: number;
	onCityChange?: (...args: any[]) => any;
	categoriesByCityCode?: any[];
	location?: any;
	superCategories?: any;
	languageMap?: any[];
	citiesMap?: any;
	host?: string;
	showDownloadHeadoutApp?: boolean;
	showSearch?: boolean;
	transparentBar?: boolean;
	isAtTop?: boolean;
};

type HeaderElementsState = any;

class HeaderElements extends React.Component<
	HeaderElementsProps,
	HeaderElementsState
> {
	state = {
		headerClass: '',
		showLanguageDropdown: false,
		showCurrencyDropdown: false,
		showMoreElementsDropdown: false,
		showAppDownloadDropdown: false,
		selectedCategoryId: ALL_COLLECTIONS,
		isHeaderCollectionsScrolled: false,
		isHeaderCollectionScrollable: false,
	};
	collectionHeaderListRef = createRef<HTMLDivElement>();

	componentDidMount() {
		const {
			currentCityCode,
			// @ts-expect-error TS(2339): Property 'onFetchCategoriesByCityCode' does not ex... Remove this comment to see the full error message
			onFetchCategoriesByCityCode,
			// @ts-expect-error TS(2339): Property 'onFetchCollections' does not exist on ty... Remove this comment to see the full error message
			onFetchCollections,
			paramLang,
			currentPage,
		} = this.props;
		onFetchCategoriesByCityCode({
			cityCode: currentCityCode || GLOBAL_CITY_CODE,
			lang: paramLang,
		});
		onFetchCollections({
			cityCode: currentCityCode || GLOBAL_CITY_CODE,
			lang: paramLang,
			limit: getCollectionLimit(currentPage!),
		});
		const elem = this.selfRef.current;

		if (!isServer()) {
			const observer = new MutationObserver(
				(mutationsList, observerRef) => {
					mutationsList.forEach(mutation => {
						const removedNodes = mutation.removedNodes;
						if (
							mutation.type === 'childList' &&
							checkIfClassNameExistsInList(
								// @ts-expect-error TS(2345): Argument of type 'NodeList' is not assignable to p... Remove this comment to see the full error message
								removedNodes,
								'webengage-push-down',
							)
						) {
							if (elem) {
								(elem as any).style.top = '0';
							}
							observerRef.disconnect();
						}
						const addedNodes = mutation.addedNodes;
						if (
							mutation.type === 'childList' &&
							checkIfClassNameExistsInList(
								// @ts-expect-error TS(2345): Argument of type 'NodeList' is not assignable to p... Remove this comment to see the full error message
								addedNodes,
								'webengage-push-down',
							)
						) {
							this.setState({
								additionalPopupNodeLoaded: true,
							});
						}
					});
				},
			);
			observer.observe(document, {
				childList: true,
				subtree: true,
				attributes: true,
				characterData: false,
			});
		}
		this.checkCollectionHeaderDivOverflow();
	}

	componentDidUpdate(
		prevProps: HeaderElementsProps,
		prevState: HeaderElementsState,
	) {
		// @ts-expect-error TS(2339): Property 'additionalPopupNodeLoaded' does not exis... Remove this comment to see the full error message
		const { additionalPopupNodeLoaded } = this.state;
		const {
			// @ts-expect-error TS(2339): Property 'onFetchCategoriesByCityCode' does not ex... Remove this comment to see the full error message
			onFetchCategoriesByCityCode,
			currentCityCode,
			// @ts-expect-error TS(2339): Property 'onFetchCollections' does not exist on ty... Remove this comment to see the full error message
			onFetchCollections,
			paramLang,
			currentPage,
		} = this.props;
		if (
			typeof prevProps.currentCityCode === 'undefined' &&
			currentCityCode
		) {
			onFetchCategoriesByCityCode({
				cityCode: currentCityCode,
				lang: paramLang,
			});
			onFetchCollections({
				cityCode: currentCityCode,
				lang: paramLang,
				limit: getCollectionLimit(currentPage!),
			});
		}
		if (
			prevState.additionalPopupNodeLoaded !== additionalPopupNodeLoaded &&
			additionalPopupNodeLoaded
		) {
			const elem = this.selfRef && this.selfRef.current;
			const additionalPopUpNode = getNodeFromClassName(
				'webengage-push-down',
			);
			let additionalPopUpHeight =
				getHeightForContainerInPixels(additionalPopUpNode);
			const heightChangeInterval = setInterval(() => {
				const newAdditionalPopUpHeight =
					getHeightForContainerInPixels(additionalPopUpNode);
				if (
					newAdditionalPopUpHeight === additionalPopUpHeight &&
					newAdditionalPopUpHeight
				) {
					clearInterval(heightChangeInterval);
				}
				if (elem) {
					(elem as any).style.top = `${newAdditionalPopUpHeight}px`;
				}
				additionalPopUpHeight = newAdditionalPopUpHeight;
			}, 25);
		}
	}

	componentDidCatch(err: any, errInfo: any) {
		error(err, errInfo);
	}

	setShowLanguageDropdown = (languageDisplayOverrideFlag?: boolean) => {
		this.setState({
			showCurrencyDropdown: false,
			showLanguageDropdown:
				languageDisplayOverrideFlag ?? !this.state.showLanguageDropdown,
			showAppDownloadDropdown: false,
		});
	};

	setShowAppDownloadDropdown = (val: any) => {
		if ((this as any).timer && val) {
			clearTimeout((this as any).timer);
		}
		if (!val) {
			(this as any).timer = setTimeout(() => {
				this.setState({ showAppDownloadDropdown: val });
			}, 40);
		} else {
			this.setState({
				showCurrencyDropdown: false,
				showLanguageDropdown: false,
				showMoreElementsDropdown: false,
				showAppDownloadDropdown: val,
			});
		}
	};

	getCollectionsClickUrl = () => {
		const { paramLang, currentCityCode } = this.props;
		const langPrefix = paramLang ? `/${paramLang}` : '';
		return `${langPrefix}/categories/${getWebPathString(
			currentCityCode || 'NEW_YORK',
		)}`;
	};

	getCollectionsClickHref = () => {
		const { paramLang } = this.props;
		const langPrefix = paramLang && paramLang !== 'en' ? `/[lang]` : '';
		return `${langPrefix}/categories/[city]`;
	};

	getProductClickUrl = () => {
		const { paramLang, currentCityCode } = this.props;
		const langPrefix = paramLang ? `/${paramLang}` : '';
		return `${langPrefix}/tours/${getWebPathString(
			currentCityCode || 'NEW_YORK',
		)}?sort-type=${SORT_TYPE.POPULARITY}`;
	};

	getTopCollectionsLink = () => {
		const {
			// @ts-expect-error TS(2339): Property 'topCollectionsInfoByCityCode' does not e... Remove this comment to see the full error message
			topCollectionsInfoByCityCode,
			paramLang: lang,
			currentCityCode,
		} = this.props;
		return (
			topCollectionsInfoByCityCode &&
			topCollectionsInfoByCityCode.map((collectionCardInfo: any) => {
				const { id, displayName, urlSlugs, cityCode } =
					collectionCardInfo;
				const collectionUrlAsPath = getCollectionUrlAsPath({
					urlSlugs,
					lang,
					city:
						currentCityCode !== cityCode &&
						currentCityCode !== GLOBAL_CITY_CODE
							? currentCityCode
							: '',
				});
				const collectionUrlHref = getCollectionUrlHref({
					id,
					lang,
					city: currentCityCode !== cityCode ? currentCityCode : '',
				});
				return (
					<Link
						href={collectionUrlHref}
						as={collectionUrlAsPath}
						passHref
						key={id}
						className={'header-element-wrapper'}
					>
						<div className='header-element'>
							<StyledSpan className={'header-element-text'}>
								{displayName}
							</StyledSpan>
						</div>
					</Link>
				);
			})
		);
	};

	getHeaderLeftItems = () => {
		const { host, currentPage } = this.props;

		if (!showCategories({ host })) return null;

		const bestSellers = (
			<div
				// @ts-expect-error TS(2322): Type '{ children: Element; href: string; onClick: ... Remove this comment to see the full error message
				href={`${this.props.paramLang ? '/[lang]' : ''}/tours/[city]`}
				onClick={() => {
					Router.push(this.getProductClickUrl());
				}}
				key='best-sellers'
			>
				<div
					className={`header-element-wrapper ${
						currentPage === PAGE_TYPE.COLLECTION ? 'selected' : ''
					}`}
				>
					<div className='header-element'>
						<StyledSpan className='header-element-text'>
							{strings.HD_BEST_SELLERS}
						</StyledSpan>
					</div>
				</div>
			</div>
		);

		const topThreeCollections = this.getTopCollectionsLink();

		return [bestSellers, topThreeCollections && [...topThreeCollections]];
	};

	getAllCategoriesTab = () => {
		const { currentCityCode, currentPage, paramLang } = this.props;
		const { selectedCategoryId, isHeaderCollectionsScrolled } = this.state;

		const changeCategory = (category: any) => {
			this.setState({ selectedCategoryId: category });
		};

		const text = (
			<div className='categories-dropdown-header'>
				<div className='categories-dropdown-large-text'>
					<HamburgerIcon className={'menu-icon'} />
					<StyledSpan className={'header-element-text'}>
						{strings.ALL_CATEGORIES}
					</StyledSpan>
				</div>
			</div>
		);

		return (
			<HoverLink
				href={this.getCollectionsClickHref()}
				className={classNames(
					`header-element-wrapper headout-picks-popup-div browse-by-categories-popup`,
					currentPage === PAGE_TYPE.COLLECTION && 'selected',
					isHeaderCollectionsScrolled && 'scrolled',
				)}
				changeCategory={changeCategory}
				text={text}
				isLink={false}
				key='headout-picks'
				isHeaderDropdown
			>
				<CategoriesDropdownInformation
					// @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
					lang={paramLang}
					cityCode={currentCityCode || GLOBAL_CITY_CODE}
					selectedCategoryId={selectedCategoryId}
					setSelectedCategoryId={changeCategory}
					isHeaderDropdown
				/>
			</HoverLink>
		);
	};

	// @ts-expect-error TS(7031): Binding element 'nextCityCode' implicitly has an '... Remove this comment to see the full error message
	cityChange = ({ cityCode: nextCityCode }) => {
		const {
			currentCityCode: prevCityCode,
			onCitySelected,
			onCityChange,
		} = this.props;
		if (prevCityCode !== nextCityCode) {
			// @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
			onCitySelected({ cityCode: nextCityCode });
		} else {
			// @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
			onCityChange({ cityCode: nextCityCode });
		}
	};

	hideHeaderSecondRow = () => {
		const { location } = this.props;

		return (
			isHomePageUrl(location.pathname) ||
			isExperiencePageUrl(location.pathname) ||
			doesCurrentRouteIncludeString('buy-gift-credits', location) ||
			doesCurrentRouteIncludeString('/cities', location) ||
			doesCurrentRouteIncludeString('collections', location) ||
			doesCurrentRouteIncludeString('bilink-generator', location) ||
			doesCurrentRouteIncludeString('/confirmation', location) ||
			doesCurrentRouteIncludeString('/alts-cancel', location) ||
			doesCurrentRouteIncludeString('/audio-guide', location) ||
			doesCurrentRouteIncludeString('/apps', location)
		);
	};

	showHeaderLanguageOnly = () => {
		const { location } = this.props;
		const isAudioGuidePage = doesCurrentRouteIncludeString(
			'/audio-guide',
			location,
		);
		return isAudioGuidePage;
	};

	hideSignUpBox = () => {
		const { location } = this.props;
		const isAppTicketPage = doesCurrentRouteIncludeString(
			'/apps',
			location,
		);
		return isAppTicketPage;
	};

	handleHeaderLeftScroll = () => {
		const { current } = this.collectionHeaderListRef ?? {};

		if (current) {
			this.setState({
				...this.state,
				isHeaderCollectionsScrolled: current.scrollLeft > 0,
			});
		}
	};

	checkCollectionHeaderDivOverflow = () => {
		const { current } = this.collectionHeaderListRef ?? {};

		if (current) {
			this.setState({
				...this.state,
				isHeaderCollectionScrollable:
					current.scrollWidth > current.clientWidth,
			});
		}
	};

	selfRef = React.createRef();

	render() {
		const {
			showLanguageDropdown,
			showAppDownloadDropdown,
			isHeaderCollectionScrollable,
		} = this.state;
		const {
			landingUrl,
			host,
			fetchingStatus,
			location,
			paramLang,
			currentCityCode,
			showDownloadHeadoutApp,
			showSearch,
			citiesMap,
			currencyCode,
			languageMap,
			languageCode,
			currentPage,
			transparentBar,
			isAtTop,
		} = this.props;
		const currency =
			currencyCode ||
			// @ts-expect-error TS(2538): Type 'undefined' cannot be used as an index type.
			citiesMap?.[currentCityCode]?.country?.currency?.code ||
			'USD';
		const languageText =
			// @ts-expect-error TS(2532): Object is possibly 'undefined'.
			languageMap.find(({ code }) => code === languageCode)
				?.displayName || strings.LANGUAGE;
		const { headerClass } = this.state;
		const showHeaderSecondRow = !this.hideHeaderSecondRow();
		const isErrorPage = checkIfErrorPage(currentPage);
		const isAppTicketsPage = doesCurrentRouteIncludeString(
			'/apps',
			location,
		);
		const isTransparentHeader =
			transparentBar && isHomePageUrl(location.pathname);

		const isDarkPurpleBg = isAppTicketsPage;

		const shouldShowSearchStrip =
			!isTransparentHeader &&
			showSearch &&
			!doesCurrentRouteIncludeString('/audio-guide', location) &&
			!isAppTicketsPage;

		const isConfirmationPage = doesCurrentRouteIncludeString(
			'/confirmation',
			location,
		);

		const isWhiteLabelConfirmationPage =
			isWhitelabel({ host }) && isConfirmationPage;

		return (
			<HeaderWrapperStyled
				className={DESKTOP_HEADER_CLASSNAME}
				$currentPage={currentPage}
				$isAtTop={isAtTop}
				$transparentBg={isTransparentHeader}
				$darkPurpleBg={isDarkPurpleBg}
				// @ts-expect-error TS(2769): No overload matches this call.
				ref={this.selfRef}
			>
				<div className={`header ${headerClass}`.trim()}>
					<div className='header-first-row'>
						{/* @ts-expect-error TS(2739): Type '{ forceFullyWhitewash: any; clickUrl: string... Remove this comment to see the full error message */}
						<Logo
							forceFullyWhitewash={
								isTransparentHeader || isDarkPurpleBg
							}
							clickUrl={landingUrl || '/'}
						/>

						<Conditional if={shouldShowSearchStrip}>
							<UniversalSearch
								searchLocation={SEARCH_LOCATION.TOP}
								location={location}
							/>
						</Conditional>
						<div className='header-element-section-wrapper'>
							<Conditional
								if={!(showSearch && showHeaderSecondRow)}
							>
								<GhostViewSection />
							</Conditional>

							<Conditional if={!isErrorPage}>
								<HeaderElementSection
									// @ts-expect-error TS(2769): No overload matches this call.
									$whiteText={
										isTransparentHeader || isDarkPurpleBg
									}
									className={`notranslate ${
										showLanguageDropdown ? 'selected' : ''
									}`}
								>
									<button
										className='elementText'
										onClick={() =>
											this.setShowLanguageDropdown()
										}
									>
										{languageText} / {currency}
									</button>

									<DropdownContainer
										className={`currency-language-selector ${
											!showLanguageDropdown
												? 'hidden'
												: ''
										}`}
									>
										<LanguageCurrencyPicker
											location={location}
											paramLang={paramLang}
											languageCode={languageCode}
											currencyCode={currency}
											onCloseModal={() =>
												this.setShowLanguageDropdown(
													false,
												)
											}
										/>
									</DropdownContainer>
								</HeaderElementSection>
							</Conditional>

							<Conditional if={!this.showHeaderLanguageOnly()}>
								{isWhiteLabelConfirmationPage ? (
									<a
										href='https://www.headout.com/help'
										target='_blank'
										rel='noreferrer'
										onClick={() =>
											trackEvent({
												eventName:
													'Help Navbar Button Clicked',
											})
										}
									>
										<HeaderElementSection
											// @ts-expect-error TS(2769): No overload matches this call.
											$whiteText={
												isTransparentHeader ||
												isDarkPurpleBg
											}
										>
											<HelpIcon
												stroke={
													(isTransparentHeader ||
														isDarkPurpleBg) &&
													'#fff'
												}
												className='helpIcon'
											/>
											<span className='elementText'>
												{strings.CMN_CONTACT.help}
											</span>
										</HeaderElementSection>
									</a>
								) : (
									<Link
										href={`${
											paramLang && paramLang !== 'en'
												? `/${paramLang}`
												: ''
										}/help/`}
										passHref
										onClick={() =>
											trackEvent({
												eventName:
													'Help Navbar Button Clicked',
											})
										}
									>
										<HeaderElementSection
											// @ts-expect-error TS(2769): No overload matches this call.
											$whiteText={
												isTransparentHeader ||
												isDarkPurpleBg
											}
										>
											<HelpIcon
												stroke={
													(isTransparentHeader ||
														isDarkPurpleBg) &&
													'#fff'
												}
												className='helpIcon'
											/>
											<span className='elementText'>
												{strings.CMN_CONTACT.help}
											</span>
										</HeaderElementSection>
									</Link>
								)}
								<Conditional if={!this.hideSignUpBox()}>
									<HeaderLoginBox
										// @ts-expect-error TS(2769): No overload matches this call.
										secondaryButton={
											isTransparentHeader ||
											isDarkPurpleBg
										}
									/>
									<Loader fetchingStatus={fetchingStatus} />
								</Conditional>
							</Conditional>
						</div>
					</div>
					{showHeaderSecondRow && (
						<nav
							className='header-second-row'
							aria-label='Main navigation'
						>
							<div
								className={classNames(
									'left',
									isHeaderCollectionScrollable &&
										'scrollable',
								)}
							>
								{this.getAllCategoriesTab()}

								<div
									ref={this.collectionHeaderListRef}
									className='left-scroll-container'
									onScroll={this.handleHeaderLeftScroll}
								>
									{this.getHeaderLeftItems()}
								</div>
							</div>
							<div className='right'>
								<Conditional if={showDownloadHeadoutApp}>
									<AppDownloadSection
										onFocus={() => {
											this.setShowAppDownloadDropdown(
												true,
											);
										}}
										onBlur={() => {
											this.setShowAppDownloadDropdown(
												false,
											);
										}}
										onMouseEnter={() =>
											this.setShowAppDownloadDropdown(
												true,
											)
										}
										onMouseLeave={() =>
											this.setShowAppDownloadDropdown(
												false,
											)
										}
										role='button'
										tabIndex={0}
										aria-expanded={
											this.state.showAppDownloadDropdown
										}
									>
										<AppIconSvg className='app-icon' />
										<span className='elementText'>
											{strings.CMN_DOWNLOAD_APP}
										</span>
										<DropdownContainer
											className={`full-width app-download-section ${
												showAppDownloadDropdown
													? 'show-app-section'
													: ''
											}`}
										>
											<DownloadAppSection />
										</DropdownContainer>
									</AppDownloadSection>
								</Conditional>
							</div>
						</nav>
					)}
				</div>
			</HeaderWrapperStyled>
		);
	}
}

const mapStateToProps = (state: any, ownProps: any) => {
	const currentCityCode = getCurrentCityCode(state);
	const { location } = ownProps;
	const { query } = location;
	const { lang: paramLang } = query;
	const user = getUser(state);
	const localizedContentLanguageCode = getLocalizedContentLanguageCode(state);

	return {
		currentCityCode,
		user,
		query,
		paramLang,
		location,
		localizedContentLanguageCode,
		currentPage: getCurrentPage(state),
		languageCode: getCurrentLanguageCode(state),
		currencyCode: getCurrentCurrency(state),
		currentCityPhoneNumber: getCurrentCity(state)
			? getCurrentCity(state)?.phoneNumber
			: HEADOUT_SUPPORT_LINE,
		fetchingStatus: getFetchingStatus(state),
		languageMap: getLanguages(state),
		citiesMap: getCitiesMap(state),
		host: getHost(state),
		landingUrl: getUserLandingUrl(state),
		currenciesMap: getCurrenciesMap(state),
		categoriesByCityCode: getCategoriesInfoByCityCode(
			state,
			currentCityCode,
		),
		topCollectionsInfoByCityCode: getTopCollectionCardInfoByCityCode(
			state,
			currentCityCode,
		),
		isBot: isBot(state),
		showDownloadHeadoutApp:
			getDomainConfig(state)?.['showDownloadHeadoutApp'],
		showSearch: getDomainConfig(state)?.['showSearch'],
	};
};

const mapDispatchToProps = {
	onFetchCategoriesByCityCode: fetchCategoriesByCityCode,
	onCitySelected: changeCity,
	onFetchCollections: fetchCollections,
};

const Header = connect(mapStateToProps, mapDispatchToProps)(HeaderElements);

export default Header;
