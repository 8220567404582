import React, { useRef } from 'react';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import useCaptureClickOutside from 'Hooks/useCaptureClickOutside';

import { strings } from 'Constants/strings';

import colors from 'Static/typography/colors';
import TYPE_LABELS from 'Static/typography/labels';

import CurrencyPicker from './currencyPickerList';
import LanguageSelectorList from './languagePickerList';

const StyledContainer = styled.div`
	width: 57.75rem;
	max-height: 27rem;
	padding: 1.25rem;
	background-color: ${colors.WHITE};
	display: grid;
	grid-template-columns: 140px 1fr;
`;
const StyledPickerContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	height: 100%;
	gap: 4px;
	max-height: 100%;
	overflow-y: auto;
	&.first-col {
		padding-right: 1.5rem;
	}
	&.first-col::after {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0px;
		border-right: 1px solid ${colors.GREY_DS.G6};
	}
	&.second-col {
		padding-left: 1.5rem;
		overflow-y: scroll;
		&::-webkit-scrollbar {
			width: 4px;
		}
		&::-webkit-scrollbar-track {
			background-color: transparent;
		}
		&::-webkit-scrollbar-thumb {
			background-color: ${colors.GREY_DS.G6};
			border-radius: 8px;
		}
	}
`;
const StyledHeading = styled.h3`
	position: sticky;
	top: 0rem;
	${getFontDetailsByLabel(TYPE_LABELS.HEADING_PRODUCT_CARD)}
	color: ${colors.GREY_DS.G2};
	padding: 0 0 6px 8px;
	width: 100%;
	background-color: white;
`;

const LanguageCurrencyPicker = ({
	onCloseModal,
	paramLang,
	languageCode,
	location,
	currencyCode,
}: any) => {
	const pickerRef = useRef(null);
	useCaptureClickOutside(pickerRef, onCloseModal);
	return (
		<StyledContainer ref={pickerRef}>
			<StyledPickerContainer className='first-col'>
				<StyledHeading>{strings.LANGUAGE}</StyledHeading>
				<LanguageSelectorList
					location={location}
					paramLang={paramLang}
					languageCode={languageCode}
					onAfterLanguageSelect={onCloseModal}
				/>
			</StyledPickerContainer>
			<StyledPickerContainer className='second-col'>
				<CurrencyPicker
					location={location}
					paramLang={paramLang}
					currencyCode={currencyCode}
					onChange={onCloseModal}
				/>
			</StyledPickerContainer>
		</StyledContainer>
	);
};

export default LanguageCurrencyPicker;
