import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { notify } from 'Components/common/notify';

import {
	getApiCurrencyParameter,
	getApiLanguageParameter,
	getBaseRequestOptions,
} from 'Utils/fetchUtils';
import fetch from 'Utils/fetchWrapper';
import { error } from 'Utils/logUtils';
import { getCurrentCurrency } from 'Utils/stateUtils';
import { getApiCDNBaseUrlV2 } from 'Utils/urlUtils';

import {
	receiveCategories,
	receiveCategorySections,
	receiveSubcategoryFiltersByCategoryId,
	requestCategories,
} from 'Actions/categories';
import { receiveProducts } from 'Actions/product';
import { setAPIServerAPIStatus } from 'Actions/serverStatus';

import { GLOBAL_CITY_CODE } from 'Constants/constants';

export const fetchCategoriesByCityCode =
	({
		cityCode = GLOBAL_CITY_CODE,
		lang = 'en',
	}): ThunkAction<void, any, unknown, Action<string>> =>
	(dispatch, getState) => {
		const cityCodeParam =
			cityCode && cityCode !== GLOBAL_CITY_CODE ? `city=${cityCode}` : '';
		const langParam = getApiLanguageParameter(lang);
		const queryParams = [cityCodeParam];
		let queryString = queryParams.filter(query => !!query).join('&');
		queryString += langParam;

		const url = `${getApiCDNBaseUrlV2({
			state: getState(),
		})}/api/v2/category?${queryString}`;

		dispatch(requestCategories(cityCode));
		const options = getBaseRequestOptions(getState());

		return fetch(url, options)
			.then(response => response.json())
			.then(json => {
				dispatch(receiveCategories(cityCode, json, url));
			})
			.catch(err => {
				error(err);
				notify.showNetworkError(err);
			});
	};

export const fetchCategoryFeed =
	({
		cityCode = GLOBAL_CITY_CODE,
		lang = 'en',
	}): ThunkAction<void, any, unknown, Action<string>> =>
	(dispatch, getState) => {
		const currencyCode = getCurrentCurrency(getState());
		const currencyParam = getApiCurrencyParameter(getState(), currencyCode);
		const langParam = getApiLanguageParameter(lang);
		const cityCodeParam =
			cityCode && cityCode !== GLOBAL_CITY_CODE ? cityCode : '';
		const url = `${getApiCDNBaseUrlV2({
			state: getState(),
		})}/api/v2/city/${cityCodeParam}/sections?${currencyParam}${langParam}`;

		const options = getBaseRequestOptions(getState());

		return fetch(url, options)
			.then(response => response.json())
			.then(json => {
				const { sections, city } = json;
				const productCards = sections.reduce(
					(acc: any, section: any) => [...acc, ...section.tourGroups],
					[],
				);
				dispatch(receiveProducts({ productCards }));
				dispatch(receiveCategorySections(sections, city));
			})
			.catch(err => {
				dispatch(setAPIServerAPIStatus(url, err.status));
				error(err);
				notify.showNetworkError(err);
			});
	};

export const fetchSubcategoryFiltersByCategoryId =
	({
		categoryId,
		cityCode,
		minProductCount,
		lang = 'en',
		useCollectionFilter = false,
	}: {
		categoryId: number;
		cityCode: string;
		minProductCount: number;
		lang?: string;
		useCollectionFilter?: boolean;
	}): ThunkAction<void, any, unknown, Action<string>> =>
	(dispatch, getState) => {
		const langParam = getApiLanguageParameter(lang);

		const url = `${getApiCDNBaseUrlV2({
			state: getState(),
		})}/api/v2/category/${categoryId}/subcategories?city=${cityCode}${langParam}&product-count-filter=${minProductCount}&use-collection-filter=${useCollectionFilter}`;

		const options = getBaseRequestOptions(getState());

		return fetch(url, options)
			.then(response => response.json())
			.then(json => {
				const { subCategories } = json;

				dispatch(
					receiveSubcategoryFiltersByCategoryId({
						subCategories,
						categoryId,
						useCollectionFilter: useCollectionFilter,
					}),
				);
			})
			.catch(err => {
				error(err);
				notify.showNetworkError(err);
			});
	};
