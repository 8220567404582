import React from 'react';
import { connect } from 'react-redux';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import getFontDetailsByLabel from '@headout/aer/src/tokens/typography';

import LH3 from 'Components/common/localizedTags/localizedHeading3';

import { setCurrencyCookie } from 'Utils/cookieUtils';
import {
	getCurrencySymbolString,
	getTopAndMoreCurrencies,
} from 'Utils/currencyUtils';
import {
	getCurrenciesMap,
	getCurrencyCodesListFromMap,
	getGeolocationCurrency,
} from 'Utils/stateUtils';

import { changeCurrency } from 'Actions/currency';

import { TOP_CURRENCIES } from 'Constants/constants';
import { strings } from 'Constants/strings';

import colors from 'Static/typography/colors';
import TYPE_LABELS from 'Static/typography/labels';

const StyledPickerItem = styled.div<{ $isActive: boolean }>`
	padding: 0.5rem;
	width: 100%;
	max-width: 235px;
	overflow: hidden;
	border-radius: 4px;
	${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_REGULAR)}

	color: ${({ $isActive }) =>
		$isActive ? colors.BRAND_COLORS.PURPS : colors.GREY_DS.G3};
	background-color: ${({ $isActive }) => $isActive && colors.GREY.FLOATING};

	display: flex;
	align-items: center;
	gap: 4px;
	white-space: nowrap;
	min-height: 2rem;
	&:hover {
		background-color: ${colors.GREY_DS.G8};
	}
	.symbol-bold {
		${getFontDetailsByLabel(TYPE_LABELS.UI_LABEL_REGULAR_HEAVY)}
	}
`;
const StyledGridContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.25rem;
`;
const StyledGridRow = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	gap: 4px;
	flex-wrap: wrap;
`;
const StyledHeadingContainer = styled.div`
	width: 100%;
	position: sticky;
	top: 0rem;
	margin-left: 8px;
	padding: 0 0 6px 0;
	background-color: ${colors.WHITE};
	h3.currency-heading {
		position: relative;
		display: inline-flex;
		z-index: 1;
		padding-right: 0.5rem;
		background-color: ${colors.WHITE};
		color: ${colors.GREY_DS.G2};
		${getFontDetailsByLabel(TYPE_LABELS.HEADING_PRODUCT_CARD)}
	}
	span.horizontal-line {
		display: inline-block;
		position: absolute;
		top: 0.625rem;
		left: 0;
		right: 0;
		height: 1px;
		background-color: ${colors.GREY_DS.G6};
		width: 100%;
		margin: 0 auto;
		background-image: -webkit-linear-gradient(
			left,
			${colors.GREY_DS.G6} 50%,
			white 95%
		);
	}
`;
const CurrencyPickerList = (props: any) => {
	const {
		currenciesMap,
		geolocationCurrency,
		currencyCode: currentCurrencyCode,
		onCurrencySelected,
		onChange,
	} = props;
	let currencyCodesList = Array.from(
		new Set([
			...TOP_CURRENCIES,
			...getCurrencyCodesListFromMap(currenciesMap),
		]),
	);

	if (currencyCodesList.indexOf(geolocationCurrency) !== -1) {
		//removes the geolocation currency from the current order
		currencyCodesList.splice(
			currencyCodesList.indexOf(geolocationCurrency),
			1,
		);
		//inserts the same at 0/1 index
		currencyCodesList.splice(
			currentCurrencyCode ? 1 : 0,
			0,
			geolocationCurrency,
		);
	}
	const [topCurrencyCodesList, otherCurrencyCodesList] =
		getTopAndMoreCurrencies(currencyCodesList);

	const onCurrencyChangeClick = (currencyCode: any) => {
		if (currentCurrencyCode !== currencyCode) {
			onCurrencySelected({ currencyCode });
		}
		if (onChange) onChange();
	};

	return (
		<StyledGridContainer>
			<StyledGridRow>
				<StyledHeadingContainer>
					<LH3 className='currency-heading'>
						{strings.POPULAR_CURRENCIES}
					</LH3>
					<span className='horizontal-line' />
				</StyledHeadingContainer>
				{topCurrencyCodesList.map((currencyCode, index) => (
					<CurrencyItem
						currency={currenciesMap?.[currencyCode]}
						currentCurrencyCode={currentCurrencyCode}
						onClick={onCurrencyChangeClick}
						key={index}
					/>
				))}
			</StyledGridRow>
			<StyledGridRow>
				<StyledHeadingContainer>
					<LH3 className='currency-heading'>
						{strings.MORE_CURRENCIES}
					</LH3>
					<span className='horizontal-line' />
				</StyledHeadingContainer>
				{otherCurrencyCodesList.map((currencyCode, index) => (
					<CurrencyItem
						currency={currenciesMap?.[currencyCode]}
						currentCurrencyCode={currentCurrencyCode}
						onClick={onCurrencyChangeClick}
						key={index}
					/>
				))}
			</StyledGridRow>
		</StyledGridContainer>
	);
};

const CurrencyItem = ({
	currency,
	currentCurrencyCode,
	onClick,
}: {
	currency: any;
	currentCurrencyCode: string;
	onClick: any;
}) => {
	if (!currency) return null;
	const { code, currencyName, localSymbol } = currency;
	const symbolString = getCurrencySymbolString(code, localSymbol);

	return (
		<StyledPickerItem
			$isActive={code === currentCurrencyCode}
			onClick={() => onClick(code)}
		>
			<div className='symbol-bold'>{symbolString} • </div>
			{currencyName}
		</StyledPickerItem>
	);
};

const mapStateToProps = (state: any) => {
	const currenciesMap = getCurrenciesMap(state) || {};
	return {
		currenciesMap,
		geolocationCurrency: getGeolocationCurrency(state),
	};
};

const mapDispatchToProps = (dispatch: any) => ({
	onCurrencySelected: ({ currencyCode }: any) => {
		dispatch(changeCurrency({ currencyCode }));
		setCurrencyCookie(currencyCode);

		window.location.reload();
	},
});

const CurrencyPicker = connect(
	mapStateToProps,
	mapDispatchToProps,
)(CurrencyPickerList);

export default CurrencyPicker;
