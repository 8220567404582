import React from 'react';
import Link from 'next/link';
/* eslint-disable-next-line no-restricted-imports */
import styled from 'styled-components';

import { ALL_COLLECTIONS } from 'Constants/constants';

type Props = {
	href?: string;
	className?: string;
	onClick?: (...args: any[]) => any;
	text?: React.ReactNode;
	isLink?: boolean;
	isHeaderDropdown?: boolean;
	changeCategory?: (...args: any[]) => any;
	children: React.ReactNode;
};

type State = any;

const StyledLinkTextElement = styled.div`
	&.headout-picks-popup-div .popup-container .popup-div {
		background-color: #fff;
		box-shadow: none;
		line-height: normal;
		position: absolute;
		top: 2rem;
		white-space: normal;
		z-index: 200;
	}

	&.browse-by-categories-popup .popup-container .popup-div {
		display: flex;
		justify-content: center;
		left: 0;
	}
`;

class HoverLink extends React.Component<Props, State> {
	state = { showPopup: false, style: {} };

	onClick = () => {
		const { showPopup } = this.state;
		showPopup ? this.hidePopupBox() : this.showPopupBox();
	};

	componentDidMount() {
		const { isHeaderDropdown } = this.props;
		if (isHeaderDropdown && typeof window !== 'undefined') {
			const headerWidth =
				(typeof document !== 'undefined' &&
					(document.querySelector('.header') as any)?.offsetWidth) ||
				1200;
			this.setState({
				style: { left: (headerWidth - (window?.innerWidth || 0)) / 2 },
			});
		}
	}

	getLinkTextElement = () => {
		// @ts-expect-error TS(2339): Property 'to' does not exist on type 'Readonly<Pro... Remove this comment to see the full error message
		const { to, className, text, children, isLink, href } = this.props;
		const { showPopup } = this.state;
		if (!isLink) {
			return (
				<StyledLinkTextElement
					className={className}
					onClick={this.onClick}
					onMouseEnter={this.showPopupBox}
					onMouseLeave={this.hidePopupBox}
				>
					<div className='header-element'>
						<span className={showPopup ? 'highlightText' : ''}>
							{text}
						</span>
					</div>
					<div
						className={`popup-container ${
							showPopup ? 'show-popup' : ''
						}`}
						style={this.state.style}
					>
						<div
							className='popup-div'
							onClick={() => this.onClick()}
						>
							{children}
						</div>
					</div>
				</StyledLinkTextElement>
			);
		}
		return (
			// @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
			<Link prefetch={false} href={href} as={to} legacyBehavior>
				<StyledLinkTextElement
					as={'a'}
					className={className}
					onClick={this.onClick}
					onMouseEnter={this.showPopupBox}
					onMouseLeave={this.hidePopupBox}
				>
					<div className='header-element'>
						<span>{text}</span>
					</div>
				</StyledLinkTextElement>
			</Link>
		);
	};

	toggleHover = () => {
		this.setState({ showHover: !(this.state as any).showHover });
	};

	showPopupBox = () => {
		if ((this as any).timer) {
			clearTimeout((this as any).timer);
		}
		this.setState({ showPopup: true });
	};

	hidePopupBox = () => {
		const { changeCategory } = this.props;
		(this as any).timer = setTimeout(() => {
			if (changeCategory) changeCategory(ALL_COLLECTIONS);
			this.setState({ showPopup: false });
		}, 10);
	};

	render() {
		return this.getLinkTextElement();
	}
}

export default HoverLink;
